<template>
    <div>
        <div class="homes" style="box-shadow: 0px 0px 6px #c4c4c4; background-color: white; padding-bottom: 20px;">
            <el-row>
                <el-col style="height: 84px; line-height: 84px; text-indent: 24px;border-bottom: 1px solid #CCCCCC;">
                    <p style="font-weight: bold;">上传</p>
                </el-col>
            </el-row>
            <el-row>
                <el-col style="height: 60px; line-height: 60px; padding-left: 20px;" :span="20">
                    <el-radio-group v-model="UploadForm.zip_type" @change="select_radio" size="mini">
                        <el-radio border :label="item.type" v-for="(item,index) in resourcesList">{{item.label}}
                        </el-radio>
                    </el-radio-group>
                </el-col>
                <el-col :span='4' style="margin-top: 22px;" v-show="showrelation">
                    <el-switch v-model="relation" active-text='是否关联脚本'></el-switch>
                </el-col>
            </el-row>
            <!-- <el-row>
				
			</el-row> -->
            <el-row style=" padding-bottom: 28px;" v-show="relation">

                <el-col :span="4" style="overflow: hidden; padding-left: 20px;height: 150px;">
                    <el-dialog :visible.sync="dialogVisible">
                        <img width="100%" :src="dialogImageUrl" alt="">
                    </el-dialog>
                    <el-upload list-type="picture-card" action="https://res.bimshows.cn/platform/show/showuploadfile" :auto-upload="false" accept=".jpg,.png,.JPG,.PNG"
                        :on-change="RelationUploadFormBeforeAvatarUpload" :multiple='false' :file-list="jiaobenfileListthumbnail">
                        <i slot="default" class="el-icon-picture" style="font-size: 40px;">
                            <br><span style="font-size: 14px;">点击上传图片</span>
                        </i>
                        <div slot="file" slot-scope="{file}">
                            <img class="el-upload-list__item-thumbnail" :src="file.url" alt="">
                            <span class="el-upload-list__item-actions">
                                <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
                                    <i class="el-icon-zoom-in"></i>
                                </span>
                                <span v-if="!disabled" class="el-upload-list__item-delete" @click="handleRemove_jiaoben(file)">
                                    <i class="el-icon-delete"></i>
                                </span>
                            </span>
                        </div>
                    </el-upload>
                </el-col>
                <el-col :span="20">
                    <el-upload class="upload-demo" drag multiple action="https://res.bimshows.cn/platform/show/showuploadfile" accept=".docx,.pptx,.doc,.ppt,.xlsx,.xls" :auto-upload="false"
                        :on-change="RelationOriginBeforeAvatarUpload" :multiple='false' :file-list="jiaobenfileListOrigin">
                        <i class="el-icon-upload"></i>
                        <div class="el-upload__text">word或ppt源文件拖到此处，或<em>点击上传</em></div>
                        <!-- <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过500kb</div> -->
                    </el-upload>
                </el-col>
            </el-row>
            <el-row style="border-bottom: 1px solid #CCCCCC; padding-bottom: 28px;" v-show="showrelation">
                <el-col :span="4" style="overflow: hidden; padding-left: 20px;height: 150px;">
                    <el-dialog :visible.sync="dialogVisible">
                        <img width="100%" :src="dialogImageUrl" alt="">
                    </el-dialog>
                    <el-upload list-type="picture-card" :on-success="handleAvatarSuccess" action="https://res.bimshows.cn/platform/show/showuploadfile" accept=".jpg,.png,.JPG,.PNG"
                        :auto-upload="false" :on-change="donghuaUploadFormBeforeAvatarUpload" :multiple='false' :file-list="donghuafileListthumbnail">
                        <i slot="default" class="el-icon-picture" style="font-size: 40px;">
                            <br><span style="font-size: 14px;">点击上传图片</span>
                        </i>
                        <div slot="file" slot-scope="{file}">
                            <img class="el-upload-list__item-thumbnail" :src="file.url" alt="">
                            <span class="el-upload-list__item-actions">
                                <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
                                    <i class="el-icon-zoom-in"></i>
                                </span>
                                <span v-if="!disabled" class="el-upload-list__item-delete" @click="handleRemove(file)">
                                    <i class="el-icon-delete"></i>
                                </span>
                            </span>
                        </div>
                    </el-upload>
                </el-col>
                <el-col :span="10">
                    <el-upload class="upload-demo" drag multiple action="https://res.bimshows.cn/platform/show/showuploadfile" accept=".mp4" :auto-upload="false"
                        :on-change="donghuaOriginBeforeAvatarUpload" :multiple='false' :file-list="donghuafileListmp4">
                        <i class="el-icon-upload"></i>
                        <!--  -->
                        <div class="el-upload__text">
                            将mp4格式文件拖到此次，或<em>点击上传</em>

                        </div>
                        <!-- <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过500kb</div> -->
                    </el-upload>
                </el-col>
                <el-col :span="10">
                    <el-upload class="upload-demo" drag multiple action="https://res.bimshows.cn/platform/show/showuploadfile" accept=".bfm,.bfm2,.bfm3,.bfm4,.bfm5,.zip" :auto-upload="false"
                        :on-change="donghuaOriginBeforeAvatarUploadMP4" :multiple='false' :file-list="donghuafileListbfm">
                        <i class="el-icon-upload"></i>
                        <!--  -->
                        <div class="el-upload__text">将bfm或zip格式文件拖到此次，或<em>点击上传</em></div>
                        <!-- <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过500kb</div> -->
                    </el-upload>
                </el-col>
                <el-col :span="24" v-show="bfdshow">
                    <el-upload class="upload-demo" drag multiple action="https://res.bimshows.cn/platform/show/showuploadfile" accept=".bfd" :auto-upload="false"
                        :on-change="donghuaOriginBeforeAvatarUploadBFD" :multiple='false' :file-list="donghuafileListBFD">
                        <i class="el-icon-upload"></i>
                        <!--  -->
                        <div class="el-upload__text">将大赛测评文件bfd格式文件拖到此处，或<em>点击上传</em></div>
                        <!-- <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过500kb</div> -->
                    </el-upload>
                </el-col>
            </el-row>

            <el-row style="border-bottom: 1px solid #CCCCCC; padding-bottom: 28px;" v-show="showjiaoben">
                <el-col :span="4" style="overflow: hidden; padding-left: 20px;height: 150px;">
                    <el-dialog :visible.sync="dialogVisible">
                        <img width="100%" :src="dialogImageUrl" alt="">
                    </el-dialog>
                    <el-upload list-type="picture-card" :on-success="handleAvatarSuccess" action="https://res.bimshows.cn/platform/show/showuploadfile" accept=".jpg,.png,.JPG,.PNG"
                        :auto-upload="false" :on-change="UploadFormBeforeAvatarUpload" :multiple='false' :file-list="MoxfileListthumbnail">
                        <i slot="default" class="el-icon-picture" style="font-size: 40px;">
                            <br><span style="font-size: 14px;">点击上传图片</span>
                        </i>
                        <div slot="file" slot-scope="{file}">
                            <img class="el-upload-list__item-thumbnail" :src="file.url" alt="">
                            <span class="el-upload-list__item-actions">
                                <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
                                    <i class="el-icon-zoom-in"></i>
                                </span>
                                <span v-if="!disabled" class="el-upload-list__item-delete" @click="handleRemove_zip(file)">
                                    <i class="el-icon-delete"></i>
                                </span>
                            </span>
                        </div>
                    </el-upload>
                </el-col>
                <el-col :span="20">
                    <el-upload class="upload-demo" drag action="https://res.bimshows.cn/platform/show/showuploadfile" accept=".zip , .rar , .cm3 , .bfp3 , .pak ,.b5a,.cm4" :auto-upload="false"
                        :on-change="OriginBeforeAvatarUpload" :multiple='false' :file-list="MoxfileListOrigin" :before-upload='beforeUploadz' :on-remove="removez">
                        <i class="el-icon-upload"></i>
                        <!--  -->
                        <div class="el-upload__text">将FBX或SKP模型添加zip压缩包(暂不支持其他格式)后拖到此处，<em>点击上传</em></div>
                        <!-- <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过500kb</div> -->
                    </el-upload>
                    <p v-if="this.moxfile_origin" style="text-align:center;">{{(this.moxfile_origin.size/1024/1024).toFixed(1)}}MB</p>
                </el-col>
            </el-row>

            <div v-if="textshow">
                <!--  v-show='!zidingyidonghua' -->
                <el-form ref="UploadForm" :model="UploadForm" label-width="120px" v-show='!zidingyidonghua'>
                    <el-row>
                        <el-col :span='8'>
                            <!-- <el-form-item label="版本选择" style="margin-bottom: 0; margin-top: 20px;">
                                <el-radio-group v-model="UploadForm.version_type">
                                    <el-radio label="1">3.0</el-radio>
                                    <el-radio label="3">5.0</el-radio>
                                    <el-radio label="4">6.0</el-radio>
                                </el-radio-group>
                            </el-form-item> -->
                            <!-- <el-form-item label="版本选择">
                                <el-checkbox-group v-model="UploadForm.version_type">
                                    <el-checkbox label="1" name="type">5.0</el-checkbox>
                                    <el-checkbox label="2" name="type">6.0</el-checkbox>
                                </el-checkbox-group>
                            </el-form-item> -->
                        </el-col>
                    </el-row>
                </el-form>

                <el-row style="margin-top: 28px; padding-left: 20px; margin-bottom: 22px;">
                    <el-col :span="8">
                        <el-input placeholder="请输入名称" v-model="UploadForm.name">
                            <template slot="prepend">名称</template>
                        </el-input>
                    </el-col>
                    <el-col :span="10" style="margin-left: 20px;">
                        <el-input placeholder="请输入标签" v-model="UploadForm.label">
                            <template slot="prepend">标签</template>
                        </el-input>
                    </el-col>
                    <el-col :span="4" style="margin-left: 20px;">
                        <el-input placeholder="0" v-model="UploadForm.goldcoin">
                            <template slot="prepend">金币</template>
                        </el-input>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="14" style=" padding-left: 20px; ">
                        <el-input placeholder="请简单描述" v-model="UploadForm.introduce">
                            <template slot="prepend">描述</template>
                        </el-input>
                    </el-col>
                </el-row>
                <el-form ref="UploadForm" :model="UploadForm" label-width="120px" v-show="showform">
                    <el-row>
                        <el-col :span='8'>
                            <el-form-item label="绑定">
                                <el-radio-group v-model="UploadForm.bind">
                                    <el-radio label="1">有</el-radio>
                                    <el-radio label="0">无</el-radio>
                                </el-radio-group>
                            </el-form-item>
                        </el-col>

                        <el-col :span='8'>
                            <el-form-item label="材质">
                                <el-radio-group v-model="UploadForm.mat">
                                    <el-radio label="1">有</el-radio>
                                    <el-radio label="0">无</el-radio>
                                </el-radio-group>
                            </el-form-item>
                        </el-col>
                        <el-col :span='8'>
                            <el-form-item label="塌陷">
                                <el-radio-group v-model="UploadForm.dent">
                                    <el-radio label="1">有</el-radio>
                                    <el-radio label="0">无</el-radio>
                                </el-radio-group>
                            </el-form-item>
                        </el-col>
                        <el-col :span='8'>
                            <el-form-item label="内置动画">
                                <el-radio-group v-model="UploadForm.animation">
                                    <el-radio label="1">有</el-radio>
                                    <el-radio label="0">无</el-radio>
                                </el-radio-group>
                            </el-form-item>
                        </el-col>
                        <el-col :span='8' v-show='!zidingyidonghua'>
                            <el-form-item label="自定义动画">
                                <el-radio-group v-model="UploadForm.map">
                                    <el-radio label="1">有</el-radio>
                                    <el-radio label="0">无</el-radio>
                                </el-radio-group>
                            </el-form-item>
                        </el-col>
                        <el-col :span='8' v-show='!zidingyidonghua'>
                            <el-form-item label="自定义工具">
                                <el-radio-group v-model="UploadForm.plugin">
                                    <el-radio label="1">有</el-radio>
                                    <el-radio label="0">无</el-radio>
                                </el-radio-group>
                            </el-form-item>
                        </el-col>
                        <el-col :span='8' v-show='!zidingyidonghua'>
                            <el-form-item label="自定义LOGO">
                                <el-radio-group v-model="UploadForm.is_customlogo">
                                    <el-radio label="1">有</el-radio>
                                    <el-radio label="0">无</el-radio>
                                </el-radio-group>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </div>

            <el-row v-if="Batchshow" style="margin-bottom: 10px;">
                <el-col :span='3' style="text-align: center;">
                    序号
                </el-col>
                <el-col :span='4' style="text-align: center;">
                    图片
                </el-col>
                <el-col :span='12' style="text-align: center;">
                    模型(.zip)
                </el-col>
                <el-col :span='5' style="text-align: center;">
                    操作
                </el-col>
            </el-row>
            <div v-for="(item,index) in BatchUpload" v-if="Batchshow">

                <el-row style="border-bottom: 1px solid #CCCCCC; border-top: 1px solid #CCCCCC; padding-bottom: 28px; padding-top: 28px;">
                    <el-col :span='3' style="text-align: center;line-height:150px;">
                        {{index+1}}
                    </el-col>
                    <el-col :span="4" style="overflow: hidden; padding-left: 20px;height: 150px;">
                        <el-dialog :visible.sync="dialogVisible">
                            <img width="100%" :src="dialogImageUrl" alt="">
                        </el-dialog>
                        <div @click="BatchImageclick(index)">
                            <el-upload list-type="picture-card" :on-success="handleAvatarSuccess" action="https://res.bimshows.cn/platform/show/showuploadfile" accept=".jpg,.png,.JPG,.PNG"
                                :auto-upload="false" :on-change="BatchUploadFormBeforeAvatarUpload" :multiple='false' :file-list="BatchMoxfileListthumbnail">
                                <i slot="default" class="el-icon-picture" style="font-size: 40px;">
                                    <br><span style="font-size: 14px;">点击上传图片</span>
                                </i>
                                <div slot="file" slot-scope="{file}">
                                    <img class="el-upload-list__item-thumbnail" :src="file.url" alt="">
                                    <span class="el-upload-list__item-actions">
                                        <span class="el-upload-list__item-preview" @click="BatchhandlePictureCardPreview(file)">
                                            <i class="el-icon-zoom-in"></i>
                                        </span>
                                        <span v-if="!disabled" class="el-upload-list__item-delete" @click="BatchhandleRemove_zip(file)">
                                            <i class="el-icon-delete"></i>
                                        </span>
                                    </span>
                                </div>
                            </el-upload>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div @click="BatchOriginclick(index)">
                            <el-upload class="upload-demo" drag multiple action="https://res.bimshows.cn/platform/show/showuploadfile" accept=".zip,.rar" :auto-upload="false"
                                :on-change="BatchOriginBeforeAvatarUpload" @click='BatchOriginBeforeAvatarUploadclick' :multiple='false' :file-list="BatchMoxfileListOrigin">
                                <i class="el-icon-upload"></i>
                                <!--  -->
                                <div class="el-upload__text">将FBX或SKP模型添加zip压缩包(暂不支持其他格式)后拖到此处，<em>点击上传</em></div>
                                <!-- <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过500kb</div> -->
                            </el-upload>
                        </div>
                    </el-col>
                    <el-col :span='5'>
                        <div style="display: flex;">
                            <el-button size="small" style="width: 30%;" @click="xiugaiclick(index)">编辑</el-button>
                            <el-button size="small" style="width: 30%;" @click="shanchuclick(index)">删除</el-button>
                        </div>
                    </el-col>
                </el-row>
                <el-row style="margin-top: 28px; padding-left: 20px; margin-bottom: 22px;" v-show="item.xiugai">
                    <el-col :span="8">
                        <el-input placeholder="请输入名称" v-model="item.name">
                            <template slot="prepend">名称</template>
                        </el-input>
                    </el-col>
                    <el-col :span="10" style="margin-left: 20px;">
                        <el-input placeholder="请输入标签" v-model="item.label">
                            <template slot="prepend">标签</template>
                        </el-input>
                    </el-col>
                    <el-col :span="4" style="margin-left: 20px;">
                        <el-input placeholder="0" v-model="item.goldcoin">
                            <template slot="prepend">金币</template>
                        </el-input>
                    </el-col>
                </el-row>
                <el-row v-show="item.xiugai">
                    <el-col :span="14" style=" padding-left: 20px; ">
                        <el-input placeholder="请简单描述" v-model="item.introduce">
                            <template slot="prepend">描述</template>
                        </el-input>
                    </el-col>
                </el-row>
                <el-form ref="UploadForm" :model="item" label-width="120px" v-show="item.xiugai">
                    <el-row>
                        <el-col :span='8'>
                            <el-form-item label="绑定">
                                <el-radio-group v-model="item.bind">
                                    <el-radio label="1">有</el-radio>
                                    <el-radio label="0">无</el-radio>
                                </el-radio-group>
                            </el-form-item>
                        </el-col>

                        <el-col :span='8'>
                            <el-form-item label="材质">
                                <el-radio-group v-model="item.mat">
                                    <el-radio label="1">有</el-radio>
                                    <el-radio label="0">无</el-radio>
                                </el-radio-group>
                            </el-form-item>
                        </el-col>
                        <el-col :span='8'>
                            <el-form-item label="塌陷">
                                <el-radio-group v-model="item.dent">
                                    <el-radio label="1">有</el-radio>
                                    <el-radio label="0">无</el-radio>
                                </el-radio-group>
                            </el-form-item>
                        </el-col>
                        <el-col :span='8'>
                            <el-form-item label="内置动画">
                                <el-radio-group v-model="item.animation">
                                    <el-radio label="1">有</el-radio>
                                    <el-radio label="0">无</el-radio>
                                </el-radio-group>
                            </el-form-item>
                        </el-col>
                        <el-col :span='8' v-show='!zidingyidonghua'>
                            <el-form-item label="自定义动画">
                                <el-radio-group v-model="item.map">
                                    <el-radio label="1">有</el-radio>
                                    <el-radio label="0">无</el-radio>
                                </el-radio-group>
                            </el-form-item>
                        </el-col>
                        <el-col :span='8' v-show='!zidingyidonghua'>
                            <el-form-item label="自定义工具">
                                <el-radio-group v-model="item.plugin">
                                    <el-radio label="1">有</el-radio>
                                    <el-radio label="0">无</el-radio>
                                </el-radio-group>
                            </el-form-item>
                        </el-col>
                        <el-col :span='8' v-show='!zidingyidonghua'>
                            <el-form-item label="自定义LOGO">
                                <el-radio-group v-model="item.is_customlogo">
                                    <el-radio label="1">有</el-radio>
                                    <el-radio label="0">无</el-radio>
                                </el-radio-group>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>

            </div>
            <div style="width: 100%;height: 45px; margin-top: 20px;" v-if="Batchshow" class="clearfix">
                <el-button style="width: auto;margin:0; float: left; margin-left: 20px; margin-bottom: 10px;" size="small" @click='Batchclick'>添加</el-button>
            </div>
            <el-row>
                <el-col>
                    <div style="width:90%;margin:auto">
                        <el-progress v-if="zipFlag" v-bind:percentage="zipUploadPercent" :text-inside="true" :stroke-width="26"></el-progress>
                    </div>

                    <el-button @click='ClickUpload' :disabled="shangchuan_btn"><span>点击上传</span> <span v-show="shangchuan_btn"><i class="el-icon-loading"></i></span></el-button>
                </el-col>
            </el-row>

        </div>
        <div style="box-shadow: 0px 0px 6px #c4c4c4; background-color: white; height:30vh;padding: 20px;margin-top: 15px; font-size: 14px; line-height: 24px; display: flex;">
            <div style="width: 50%;">
                <el-row>
                    <el-col>图片上传须知：</el-col>
                    <el-col class="text_int">1、要求PNG或JPG格式，无背景、无边框、无水印；</el-col>
                    <el-col class="text_int">2、大小1000像素以上10M以内。</el-col>
                </el-row>
                <el-row v-show="showjiaoben || Batchshow">
                    <el-col>模型上传须知： <span><a href="https://account.bimfilm.cn/help?leixing=bim" style='color: #b6492c; cursor: pointer;'>详细说明请见->帮助->第二章->模型上传</a> </span></el-col>
                    <el-col class="text_int">1、支持FBX、SKP格式上传，需制作成ZIP(暂不支持rar)压缩包后上传；</el-col>
                    <el-col class="text_int">2、模型文件大小300M以内；</el-col>
                    <el-col class="text_int">3、上传作品支持原作，如有侵权需上传人员承担。</el-col>
                </el-row>
                <el-row v-show="showrelation">
                    <el-col>动画上传须知：<span><a href="https://account.bimfilm.cn/help?leixing=bim" style='color: #b6492c; cursor: pointer;'>详细说明请见->帮助->第二章->动画上传</a> </span></el-col>
                    <el-col class="text_int">1、1张封面图，1个MP4视频，1个BFM动画文件分别上传到相对应栏中即可；</el-col>
                    <el-col class="text_int">2、视频格式：MP4，要求大小500M以内，视频压缩方法<em style="color:#c43e1b; cursor: pointer;" @click="yasou">如何压缩视频；</em></el-col>
                    <el-col class="text_int">3、BFM动画文件大小1G以内；</el-col>
                    <el-col class="text_int">4、可关联脚本一起上传，上传通过后可在预览动画时查看到脚本信息；</el-col>
                    <el-col class="text_int">5、视频和动画文件需保持一致，如两者不同引起购买争议，BIM秀秀网有权取消交易和下架删除并收回该资源所有收益退还买家；</el-col>
                    <el-col class="text_int">6、上传作品支持原作，如有侵权需上传人员承担。</el-col>
                </el-row>
                <el-row v-show="relation">
                    <el-col>脚本上传须知：</el-col>
                    <el-col class="text_int">1、1张封面图（可用截图工具截取文档首页、目录页或概述页）；</el-col>
                    <el-col class="text_int">2、脚本支持格式：doc、docx、ppt、pptx、xls、xlsx；</el-col>
                    <el-col class="text_int">3、可关联动画一起上传；</el-col>
                    <el-col class="text_int">4、上传作品支持原作，如有侵权需上传人员承担。</el-col>
                </el-row>
            </div>
            <div style="width: 50%;">
                <el-row>
                    <el-col>金币及收益说明：</el-col>
                    <el-col class="text_int">1、10金币=1元（人民币）；</el-col>
                    <el-col class="text_int">2、平台与个人版5：5分成，平台与企业正式版4：6分成（平台4，用户6）；</el-col>
                    <el-col class="text_int">3、统一自己定价金币，建议整数0-1000金币：0币代表免费，默认0金币；</el-col>
                    <el-col class="text_int">4、所得收益均可购买其他资源或者提现；</el-col>
                    <el-col class="text_int">5、模型上传日期超过一年，下载量低于2管理员有权修改价格或下架处理。</el-col>
                </el-row>
            </div>
        </div>
    </div>
</template>

<script>
import {
    requestShowuploadfile,
    requestaccount_resource_add,
    requestjiaobenShowuploadfile,
    resource_details,
    requestaccount_resource_update,
} from "../api/request";
import axios from "axios";
export default {
    components: {},
    data() {
        return {
            zipUploadPercent: 0, // 视频进度设置
            zipFlag: false,
            resourcesList: [
                {
                    value: "1",
                    type: ".fbx",
                    label: "上传模型",
                },
                {
                    value: "4",
                    type: ".fbxs",
                    label: "批量上传模型",
                },
                {
                    value: "2",
                    type: ".bfm",
                    label: "上传动画",
                },
                {
                    value: "3",
                    type: ".word",
                    label: "上传脚本",
                },
            ],
            UploadForm: {
                resource_type: "",
                zip_type: ".fbx",
                name: "",
                label: "",
                goldcoin: "0",
                introduce: "",
                bind: "0",
                map: "0",
                plugin: "0",
                animation: "0",
                mat: "1",
                dent: "0",
                thumbnail: "",
                pack_type: "",
                file_document_ico: "",
                file_document: "",
                file_document_ziptype: "",
                file_mp4: "",
                file_pak: "1.pak",
                file_nanite_pak: "nanite.pak",
                file_origin: "",
                is_public: 0,
                is_customlogo: "0",
                version_type: [],
            },
            dialogImageUrl: "",
            dialogVisible: false,
            disabled: false,
            relation: false,
            showrelation: false,
            showform: true,
            showjiaoben: true,
            jiaoben_filethumbnail: null,
            jiaoben_file_origin: null,
            donghua_filethumbnail: null,
            donghua_file_origin: [],
            moxfilethumbnail: null,
            moxfile_origin: null,
            modifyID: "",
            ismodify: false,

            MoxfileListOrigin: [],
            MoxfileListthumbnail: [],

            BatchMoxfileListOrigin: [],
            BatchMoxfileListthumbnail: [],

            donghuafileListbfm: [],
            donghuafileListmp4: [],
            donghuafileListthumbnail: [],

            jiaobenfileListOrigin: [],
            jiaobenfileListthumbnail: [],

            moxin_one: false,
            moxin_tow: false,

            jiaoben_one: false,
            jiaoben_tow: false,
            donghua_one: false,
            donghua_tow: false,

            xiugaijiaoben: false,
            shangchuan_btn: false,
            donghuafileListBFD: [],
            bfdshow: false,
            zidingyidonghua: true,

            BatchUpload: [
                {
                    BatchIndex: 1,
                    resource_type: "1",
                    zip_type: ".fbx",
                    name: "",
                    label: "",
                    goldcoin: "0",
                    introduce: "",
                    bind: "0",
                    map: "0",
                    plugin: "0",
                    animation: "0",
                    mat: "1",
                    dent: "0",
                    thumbnail: "",
                    pack_type: "1",
                    file_document_ico: "",
                    file_document: "",
                    file_document_ziptype: "",
                    file_mp4: "",
                    file_origin: "",
                    is_public: 0,
                    is_customlogo: "0",
                    xiugai: false,
                },
            ],
            Batchshow: false,
            textshow: true,
            BatchOriginIndex: "",
            BatchImagecIndex: "",
            isstopone: false,
            isstoptow: false,
            isBatchfile_origin: false,
            isBatchthumbnail: false,
        };
    },
    watch: {
        modifyID: function () {
            let data = {
                id: this.modifyID,
            };
            resource_details(data).then((res) => {
                this.ismodify = true;
                this.UploadForm = res.ret;
                this.UploadForm.bind = res.ret.bind.toString();
                this.UploadForm.map = res.ret.map.toString();
                this.UploadForm.plugin = res.ret.plugin.toString();
                this.UploadForm.is_customlogo =
                    res.ret.is_customlogo.toString();
                this.UploadForm.animation = res.ret.animation.toString();
                this.UploadForm.mat = res.ret.mat.toString();
                this.UploadForm.dent = res.ret.dent.toString();
                if (res.ret.resource_type == 2) {
                    this.UploadForm.zip_type = ".bfm";
                    this.showrelation = true;
                    this.showform = false;
                    this.showjiaoben = false;
                    this.relation = false;
                    this.donghuafileListbfm.push({
                        name: res.ret.file_origin,
                        url: res.ret.file_origin,
                    });
                    this.donghuafileListmp4.push({
                        name: res.ret.file_mp4,
                        url: res.ret.file_mp4,
                    });
                    this.donghuafileListBFD.push({
                        name: res.ret.file_backup,
                        url: res.ret.file_backup,
                    });
                    this.donghuafileListthumbnail.push({
                        name: res.ret.name,
                        url:
                            "https://resourcelib.oss-cn-beijing.aliyuncs.com/upload/" +
                            res.ret.thumbnail,
                    });
                } else if (res.ret.resource_type == 1) {
                    this.showrelation = false;
                    this.showform = true;
                    this.showjiaoben = true;
                    this.relation = false;
                    this.UploadForm.zip_type = ".fbx";
                    this.MoxfileListOrigin.push({
                        name: res.ret.file_origin,
                        url: res.ret.file_origin,
                    });
                    console.log(this.MoxfileListOrigin);
                    this.MoxfileListthumbnail.push({
                        name: res.ret.name,
                        url:
                            "https://resourcelib.oss-cn-beijing.aliyuncs.com/upload/" +
                            res.ret.thumbnail,
                    });
                } else if (res.ret.resource_type == 3) {
                    this.UploadForm.zip_type = ".word";
                    this.showrelation = false;
                    this.showform = false;
                    this.showjiaoben = false;
                    this.relation = true;
                    this.jiaobenfileListOrigin.push({
                        name: res.ret.file_document,
                        url: res.ret.file_document,
                    });

                    this.jiaobenfileListthumbnail.push({
                        name: res.ret.name,
                        url:
                            "https://resourcelib.oss-cn-beijing.aliyuncs.com/upload/" +
                            res.ret.thumbnail,
                    });
                }
            });
        },
    },
    methods: {
        // 上传模型清除文件时调用并清除文件大小显示
        removez(file, fileList) {
            console.log(file, fileList);
            this.moxfile_origin = null;
        },
        beforeUploadz(file) {
            console.log(file);
        },
        yasou() {
            this.common.modalnormaldownload(
                "如何压缩视频",
                "https://filedocument.oss-cn-beijing.aliyuncs.com/resource/other/视频压缩方法.docx"
            );
        },
        BatchOriginclick(index) {
            this.BatchOriginIndex = index;
        },
        BatchImageclick(index) {
            this.BatchImagecIndex = index;
        },
        Batchclick() {
            if (this.BatchUpload.length >= 5) {
                this.$message({
                    message: "批量上传仅限五个模型",
                    type: "warning",
                });
                return;
            }
            this.BatchUpload.push({
                BatchIndex: this.BatchUpload.length + 1,
                resource_type: "1",
                zip_type: ".fbx",
                name: "",
                label: "",
                goldcoin: "0",
                introduce: "",
                bind: "0",
                map: "0",
                plugin: "0",
                animation: "0",
                mat: "1",
                dent: "0",
                thumbnail: "",
                pack_type: "1",
                file_document_ico: "",
                file_document: "",
                file_document_ziptype: "",
                file_mp4: "",
                file_origin: "",
                is_public: 0,
                is_customlogo: "0",
                xiugai: false,
            });
            console.log(this.BatchUpload);
        },

        xiugaiclick(index) {
            this.BatchUpload[index].xiugai = !this.BatchUpload[index].xiugai;
        },

        shanchuclick(index) {
            if (this.BatchUpload.length > 1) {
                this.BatchUpload.splice(index, 1);
            } else {
                this.$message({
                    message: "至少保留一个",
                    type: "warning",
                    offset: "200",
                });
            }
        },

        empty() {
            this.UploadForm.name = "";
            this.UploadForm.label = "";
            this.UploadForm.goldcoin = "";
            this.UploadForm.introduce = "";
        },
        select_radio() {
            console.log(this.UploadForm.zip_type);
            this.empty();
            this.dialogImageUrl = "";
            this.dialogVisible = false;
            if (this.UploadForm.zip_type == ".fbx") {
                this.showrelation = false;
                this.showform = true;
                this.showjiaoben = true;
                this.relation = false;
                this.textshow = true;
                this.Batchshow = false;
            } else if (this.UploadForm.zip_type == ".bfm") {
                this.showrelation = true;
                this.showform = false;
                this.showjiaoben = false;
                this.relation = false;
                this.Batchshow = false;
                this.textshow = true;
            } else if (this.UploadForm.zip_type == ".fbxs") {
                this.showrelation = false;
                this.showform = false;
                this.showjiaoben = false;
                this.relation = false;
                this.Batchshow = true;
                this.textshow = false;
            }
        },
        handleRemove(file) {
            this.donghuafileListthumbnail = [];
            console.log(file);
        },
        handleRemove_zip(file) {
            this.MoxfileListthumbnail = [];
        },
        BatchhandleRemove_zip(file) {
            this.MoxfileListthumbnail = [];
        },
        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url;
            this.dialogVisible = true;
        },
        BatchhandlePictureCardPreview(file) {
            this.dialogImageUrl = file.url;
            this.dialogVisible = true;
        },
        handleDownload(file) {
            console.log(file);
        },
        handleRemove_jiaoben(file) {
            this.jiaobenfileListthumbnail = [];
        },
        ClickUpload() {
            this.shangchuan_btn = true;
            console.log(this.UploadForm.zip_type)
            console.log(this.UploadForm.version_type.length)
            if(this.UploadForm.version_type.length == 2){
                this.UploadForm.version_type = '3'
            }else{
                this.UploadForm.version_type = this.UploadForm.version_type[0]
            }
            console.log(this.UploadForm.version_type)
            if (this.UploadForm.zip_type == ".fbx") {
                this.UploadForm.resource_type = 1;
                this.UploadForm.pack_type = 1;

                if (this.moxfilethumbnail != null) {
                    this.moxupload(this.moxfilethumbnail);
                }
                if (this.moxfile_origin != null) {
                    this.moxuploadorigin(this.moxfile_origin);
                }

                if (
                    this.moxfile_origin == null &&
                    this.moxfilethumbnail == null &&
                    this.ismodify
                ) {
                    this.upload_one();
                }
            } else if (
                this.UploadForm.zip_type == ".bfm" ||
                this.UploadForm.zip_type == ".zip"
            ) {
                console.log(this.UploadForm.zip_type);
                this.UploadForm.resource_type = 2;
                this.UploadForm.pack_type = 6;

                if (this.donghua_file_origin.length != 0) {
                    console.log(this.donghua_file_origin);
                    let upload_data = [];
                    upload_data = this.donghua_file_origin.concat(
                        this.donghua_filethumbnail
                    );
                    // 		console.log(upload_data)
                    // return false
                    this.upload_donghua(upload_data);
                }

                if (this.relation) {
                    if (this.jiaoben_filethumbnail != null) {
                        this.upload_jiaoben_ico(this.jiaoben_filethumbnail, 31);
                    }
                    if (this.jiaoben_file_origin != null) {
                        this.upload_jiaoben(this.jiaoben_file_origin, 32);
                    }

                    if (
                        this.jiaoben_filethumbnail != null &&
                        this.jiaoben_file_origin != null
                    ) {
                        this.xiugaijiaoben = true;
                    }
                }
                console.log(this.donghua_file_origin);
                console.log(
                    this.jiaoben_file_origin == null,
                    this.jiaoben_filethumbnail == null,
                    this.donghua_file_origin == 0,
                    this.ismodify
                );
                if (
                    this.jiaoben_file_origin == null &&
                    this.jiaoben_filethumbnail == null &&
                    this.donghua_file_origin.length == 0 &&
                    this.ismodify
                ) {
                    this.upload_one();
                }
            } else if (this.UploadForm.zip_type == ".word") {
                this.UploadForm.resource_type = 3;
                this.UploadForm.pack_type = 7;
                this.$message({
                    message: "正在上传中，请稍后",
                    offset: 100,
                });

                if (this.jiaoben_filethumbnail != null) {
                    this.upload_jiaoben_ico(this.jiaoben_filethumbnail, 31);
                }
                if (this.jiaoben_file_origin != null) {
                    this.upload_jiaoben(this.jiaoben_file_origin, 32);
                }
                if (
                    this.jiaoben_file_origin == null &&
                    this.jiaoben_filethumbnail == null &&
                    this.ismodify
                ) {
                    this.upload_one();
                }
            } else if (this.UploadForm.zip_type == ".fbxs") {
                this.BatchUpload_fn();
            }
        },
        BatchUpload_fn() {
            console.log(this.BatchUpload);
            for (let i = 0; i < this.BatchUpload.length; i++) {
                if (
                    this.BatchUpload[i].moxfile_origin == undefined ||
                    this.BatchUpload[i].moxfilethumbnail == undefined ||
                    this.BatchUpload[i].name == ""
                ) {
                    this.$message({
                        message: this.BatchUpload[i].name + "缺少文件",
                        offset: 200,
                    });
                    return;
                }
                this.Batchmoxupload(this.BatchUpload[i], true);
                this.Batchmoxuploadorigin(this.BatchUpload[i], true);
                // this.BatchUpload[i]=this.Batchmoxupload(this.BatchUpload[i]);
                // this.BatchUpload[i]=this.Batchmoxuploadorigin(this.BatchUpload[i]);
            }
        },

        Batchmoxupload(item, isupload) {
            console.log(isupload);
            let formData = new window.FormData(),
                _this = this,
                Storage = this.Account.GetStorage();
            console.log(item);
            formData.append("file[]", item.moxfilethumbnail.raw);
            if (
                (Storage.total_storage - Storage.use_storage) * 1024 * 1024 <
                item.size
            ) {
                _this.$message({
                    message: "内部空间不足，请购买空间",
                    type: "warning",
                    offset: 100,
                });
                return;
            }
            requestShowuploadfile(formData).then((res) => {
                for (let u = 0; u < this.BatchUpload.length; u++) {
                    console.log(
                        this.BatchUpload[u].moxfile_origin.uid,
                        item.moxfile_origin.uid
                    );
                    if (
                        this.BatchUpload[u].moxfile_origin.uid ==
                        item.moxfile_origin.uid
                    ) {
                        this.BatchUpload[u].thumbnail = res.file[0];
                    }
                }
                this.isBatchthumbnail = isupload;
                this.Batchupload(item);
            });
        },

        Batchmoxuploadorigin(item, isupload) {
            console.log(isupload);
            let formData = new window.FormData(),
                _this = this,
                Storage = this.Account.GetStorage();
            console.log(item);
            formData.append("file[]", item.moxfile_origin.raw);
            if (
                (Storage.total_storage - Storage.use_storage) * 1024 * 1024 <
                item.size
            ) {
                _this.$message({
                    message: "内部空间不足，请购买空间",
                    type: "warning",
                    offset: 100,
                });
                return;
            }
            this.zipFlag = true;
            axios({
                method: "POST",
                url: "https://res.bimshows.cn/platform/show/showuploadfile",
                data: formData,
                onUploadProgress: (progressEvent) => {
                    console.log(progressEvent);
                    // progressEvent.loaded:已上传文件大小
                    // progressEvent.total:被上传文件的总大小
                    _this.zipUploadPercent = Number(
                        (
                            (progressEvent.loaded / progressEvent.total) *
                            100
                        ).toFixed(2)
                    );
                },
            }).then((res) => {
                this.zipFlag = false;
                this.zipUploadPercent = 0;
                for (let u = 0; u < this.BatchUpload.length; u++) {
                    if (
                        this.BatchUpload[u].moxfile_origin.uid ==
                        item.moxfile_origin.uid
                    ) {
                        this.BatchUpload[u].file_origin = res.file[0];
                        this.BatchUpload[u].zip_type =
                            "." + res.file[0].split(".")[1];
                    }
                }
                this.isBatchfile_origin = isupload;
                this.Batchupload(item);
            });
            // requestShowuploadfile(formData).then((res) => {
            //     for (let u = 0; u < this.BatchUpload.length; u++) {
            //         if (
            //             this.BatchUpload[u].moxfile_origin.uid ==
            //             item.moxfile_origin.uid
            //         ) {
            //             this.BatchUpload[u].file_origin = res.file[0];
            //             this.BatchUpload[u].zip_type =
            //                 "." + res.file[0].split(".")[1];
            //         }
            //     }
            //     this.isBatchfile_origin = isupload;
            //     this.Batchupload(item);
            // });
        },

        Batchupload(item) {
            console.log(this.isBatchfile_origin, this.isBatchthumbnail);
            if (
                this.isBatchfile_origin == true &&
                this.isBatchthumbnail == true
            ) {
                this.$confirm(
                    "是否同步上架，供他人购买使用并且自己获得相应收益",
                    "提示",
                    {
                        confirmButtonText: " 是、上架",
                        cancelButtonText: "不、自用",
                        type: "warning",
                    }
                )
                    .then(() => {
                        this.BatchUpload.forEach((val, index) => {
                            console.log(val, index);
                            val.is_public = 1;
                            if (
                                val.moxfile_origin.uid ==
                                item.moxfile_origin.uid
                            ) {
                                console.log(this.BatchUpload.length - 1, index);
                                if (this.BatchUpload.length - 1 == index) {
                                    this.isstoptow = true;
                                    this.isstopone = true;
                                    this.Batchupload_one(val);
                                } else {
                                    this.Batchupload_one(val);
                                }
                            }
                        });
                    })
                    .catch(() => {
                        this.BatchUpload.forEach((val, index) => {
                            console.log(val, index);
                            val.is_public = 0;
                            if (
                                val.moxfile_origin.uid ==
                                item.moxfile_origin.uid
                            ) {
                                console.log(this.BatchUpload.length - 1, index);
                                if (this.BatchUpload.length - 1 == index) {
                                    this.isstoptow = true;
                                    this.isstopone = true;
                                    this.Batchupload_one(val);
                                } else {
                                    this.Batchupload_one(val);
                                }
                            }
                        });
                    });
            }
        },
        Batchupload_one(item, isstop) {
            requestaccount_resource_add(item).then((res) => {
                if (res.code == 0) {
                    if (item.is_public == 1) {
                        this.$message({
                            message:
                                "上架成功，等待管理员审核通过后即可在网站查看或供他人购买",
                            type: "success",
                            offset: 380,
                        });
                    } else {
                        this.$message({
                            message:
                                "模型已上传，请去我的秀秀-我的上传-未上架中查看",
                            type: "success",
                            offset: 380,
                        });
                    }
                    console.log(this.isstopone, this.isstoptow);
                    if (this.isstopone && this.isstoptow) {
                        for (let u = 0; u < this.BatchUpload.length; u++) {
                            if (this.BatchUpload.length - 1 == u) {
                                this.shangchuan_btn = false;
                                setTimeout(function () {
                                    history.go(0);
                                }, 5000);
                            }
                        }
                    }
                }
            });
        },

        uploadfunction(index) {
            console.log(this.relation);
            if (this.relation && index == 2) {
                if (
                    (this.jiaoben_one &&
                        this.jiaoben_tow &&
                        this.donghua_tow) ||
                    this.ismodify
                ) {
                    this.$confirm(
                        "是否同步上架，供他人购买使用并且自己获得相应收益",
                        "提示",
                        {
                            confirmButtonText: " 是、上架",
                            cancelButtonText: "不、自用",
                            type: "warning",
                        }
                    )
                        .then(() => {
                            this.UploadForm.is_public = 1;
                            this.upload_one();
                        })
                        .catch(() => {
                            this.UploadForm.is_public = 0;
                            this.upload_one();
                        });
                }
                return;
            }

            if (this.xiugaijiaoben) {
                if (this.jiaoben_tow && this.jiaoben_one && this.ismodify) {
                    this.$confirm(
                        "是否同步上架，供他人购买使用并且自己获得相应收益",
                        "提示",
                        {
                            confirmButtonText: " 是、上架",
                            cancelButtonText: "不、自用",
                            type: "warning",
                        }
                    )
                        .then(() => {
                            this.UploadForm.is_public = 1;
                            this.upload_one();
                        })
                        .catch(() => {
                            this.UploadForm.is_public = 0;
                            this.upload_one();
                        });
                }
                return;
            }

            if (index == 1) {
                console.log(this.moxin_one, this.moxin_tow, this.ismodify);
                if ((this.moxin_one && this.moxin_tow) || this.ismodify) {
                    this.$confirm(
                        "是否同步上架，供他人购买使用并且自己获得相应收益",
                        "提示",
                        {
                            confirmButtonText: " 是、上架",
                            cancelButtonText: "不、自用",
                            type: "warning",
                        }
                    )
                        .then(() => {
                            this.UploadForm.is_public = 1;
                            this.upload_one();
                        })
                        .catch(() => {
                            this.UploadForm.is_public = 0;
                            this.upload_one();
                        });
                }
            } else if (index == 3) {
                if ((this.jiaoben_tow && this.jiaoben_one) || this.ismodify) {
                    this.$confirm(
                        "是否同步上架，供他人购买使用并且自己获得相应收益",
                        "提示",
                        {
                            confirmButtonText: " 是、上架",
                            cancelButtonText: "不、自用",
                            type: "warning",
                        }
                    )
                        .then(() => {
                            this.UploadForm.is_public = 1;
                            this.upload_one();
                        })
                        .catch(() => {
                            this.UploadForm.is_public = 0;
                            this.upload_one();
                        });
                }
            } else if (index == 2) {
                if (
                    (this.donghua_tow && this.relation == false) ||
                    this.ismodify
                ) {
                    this.$confirm(
                        "是否同步上架，供他人购买使用并且自己获得相应收益",
                        "提示",
                        {
                            confirmButtonText: " 是、上架",
                            cancelButtonText: "不、自用",
                            type: "warning",
                        }
                    )
                        .then(() => {
                            this.UploadForm.is_public = 1;
                            this.upload_one();
                        })
                        .catch(() => {
                            this.UploadForm.is_public = 0;
                            this.upload_one();
                        });
                }
            } else if (index == 3) {
                if (
                    this.jiaoben_tow &&
                    this.ismodify &&
                    this.UploadForm.resource_type == 3
                ) {
                    this.$confirm(
                        "是否同步上架，供他人购买使用并且自己获得相应收益",
                        "提示",
                        {
                            confirmButtonText: " 是、上架",
                            cancelButtonText: "不、自用",
                            type: "warning",
                        }
                    )
                        .then(() => {
                            this.UploadForm.is_public = 1;
                            this.upload_one();
                        })
                        .catch(() => {
                            this.UploadForm.is_public = 0;
                            this.upload_one();
                        });
                }
                if (
                    this.jiaoben_one &&
                    this.ismodify &&
                    this.UploadForm.resource_type == 3
                ) {
                    this.$confirm(
                        "是否同步上架，供他人购买使用并且自己获得相应收益",
                        "提示",
                        {
                            confirmButtonText: " 是、上架",
                            cancelButtonText: "不、自用",
                            type: "warning",
                        }
                    )
                        .then(() => {
                            this.UploadForm.is_public = 1;
                            this.upload_one();
                        })
                        .catch(() => {
                            this.UploadForm.is_public = 0;
                            this.upload_one();
                        });
                }
            } else if (index == 1) {
                if (this.moxin_tow && this.ismodify) {
                    this.$confirm(
                        "是否同步上架，供他人购买使用并且自己获得相应收益",
                        "提示",
                        {
                            confirmButtonText: " 是、上架",
                            cancelButtonText: "不、自用",
                            type: "warning",
                        }
                    )
                        .then(() => {
                            this.UploadForm.is_public = 1;
                            this.upload_one();
                        })
                        .catch(() => {
                            this.UploadForm.is_public = 0;
                            this.upload_one();
                        });
                }
                if (this.moxin_one && this.ismodify) {
                    this.$confirm(
                        "是否同步上架，供他人购买使用并且自己获得相应收益",
                        "提示",
                        {
                            confirmButtonText: " 是、上架",
                            cancelButtonText: "不、自用",
                            type: "warning",
                        }
                    )
                        .then(() => {
                            this.UploadForm.is_public = 1;
                            this.upload_one();
                        })
                        .catch(() => {
                            this.UploadForm.is_public = 0;
                            this.upload_one();
                        });
                }
            }
        },
        upload_one() {
            let Storage = this.Account.GetStorage();
            if (this.UploadForm.name == "") {
                this.$message({
                    message: "请填写名称",
                    type: "warning",
                    offset: 100,
                });
                return;
            }

            if (this.ismodify) {
                console.log(
                    this.donghua_tow,
                    this.jiaoben_one,
                    this.jiaoben_tow,
                    this.moxin_one,
                    this.moxin_tow
                );
                if (
                    this.donghua_tow ||
                    this.jiaoben_one ||
                    this.jiaoben_tow ||
                    this.moxin_one ||
                    this.moxin_tow
                ) {
                    this.UploadForm.state = 0;
                    this.UploadForm.pack_state = 0;
                }
                requestaccount_resource_update(this.UploadForm).then((res) => {
                    if (res.code == 0) {
                        this.$message({
                            message: "修改资源成功",
                            type: "success",
                            offset: 100,
                        });
                        this.shangchuan_btn = false;
                        setTimeout(function () {
                            history.go(0);
                        }, 5000);
                    }
                });
                return;
            }
            // setTimeout(()=>{
            requestaccount_resource_add(this.UploadForm).then((res) => {
                if (res.code == 0) {
                    if (this.UploadForm.is_public == 1) {
                        this.$message({
                            message:
                                "上架成功，等待管理员审核通过后即可在网站查看或供他人购买",
                            type: "success",
                            offset: 380,
                        });
                    } else {
                        this.$message({
                            message:
                                "模型已上传，请去我的秀秀-我的上传-未上架中查看",
                            type: "success",
                            offset: 380,
                        });
                    }

                    this.shangchuan_btn = false;
                    setTimeout(function () {
                        history.go(0);
                    }, 5000);
                }
            });
            // },4000)
        },
        handleAvatarSuccess(response, file, fileList) {
            console.log(response, file, fileList);
        },
        UploadFormBeforeAvatarUpload(file) {
            console.log(file);
            let formData = new window.FormData(),
                _this = this,
                Storage = this.Account.GetStorage();
            formData.append("file[]", file.raw);

            if (
                (Storage.total_storage - Storage.use_storage) * 1024 * 1024 <
                file.size
            ) {
                _this.$message({
                    message: "内部空间不足，请购买空间",
                    type: "warning",
                    offset: 100,
                });
                return;
            }
            _this.$message({
                message: "正在上传中，请稍后",
            });
            this.moxfilethumbnail = file;
        },
        BatchUploadFormBeforeAvatarUpload(file) {
            console.log(file);
            let formData = new window.FormData(),
                _this = this,
                Storage = this.Account.GetStorage();
            formData.append("file[]", file.raw);

            if (
                (Storage.total_storage - Storage.use_storage) * 1024 * 1024 <
                file.size
            ) {
                _this.$message({
                    message: "内部空间不足，请购买空间",
                    type: "warning",
                    offset: 100,
                });
                return;
            }
            _this.$message({
                message: "正在上传中，请稍后",
            });

            this.BatchUpload[this.BatchImagecIndex].moxfilethumbnail = file;
        },
        moxupload(item) {
            let formData = new window.FormData(),
                _this = this,
                Storage = this.Account.GetStorage();
            formData.append("file[]", item.raw);
            if (
                (Storage.total_storage - Storage.use_storage) * 1024 * 1024 <
                item.size
            ) {
                _this.$message({
                    message: "内部空间不足，请购买空间",
                    type: "warning",
                    offset: 100,
                });
                return;
            }
            // this.zipFlag = true
            // axios({
            //     method: "POST",
            //     url: "https://res.bimshows.cn/platform/show/showuploadfile",
            //     data: formData,
            //     onUploadProgress: (progressEvent) => {
            //         console.log(progressEvent);
            //         // progressEvent.loaded:已上传文件大小
            //         // progressEvent.total:被上传文件的总大小
            //         _this.zipUploadPercent = Number(
            //             (
            //                 (progressEvent.loaded / progressEvent.total) *
            //                 100
            //             ).toFixed(2)
            //         );
            //     },
            // }).then((res) => {
            // 	_this.UploadForm.thumbnail = res.file[0];
            //     _this.moxin_one = true;
            //     this.uploadfunction(1);
            // });
            requestShowuploadfile(formData).then((res) => {
                _this.UploadForm.thumbnail = res.file[0];
                _this.moxin_one = true;
                this.uploadfunction(1);
            });
        },

        moxuploadorigin(item) {
            let formData = new window.FormData(),
                _this = this,
                Storage = this.Account.GetStorage();
            console.log(item);
            formData.append("file[]", item.raw);
            if (
                (Storage.total_storage - Storage.use_storage) * 1024 * 1024 <
                item.size
            ) {
                _this.$message({
                    message: "内部空间不足，请购买空间",
                    type: "warning",
                    offset: 100,
                });
                return;
            }
            this.zipFlag = true;
            axios({
                method: "POST",
                url: "https://res.bimshows.cn/platform/show/showuploadfile",
                data: formData,
                onUploadProgress: (progressEvent) => {
                    console.log(progressEvent);
                    // progressEvent.loaded:已上传文件大小
                    // progressEvent.total:被上传文件的总大小
                    _this.zipUploadPercent = Number(
                        (
                            (progressEvent.loaded / progressEvent.total) *
                            100
                        ).toFixed(2)
                    );
                },
            }).then((res) => {
                this.zipFlag = false;
                this.zipUploadPercent = 0;
                this.UploadForm.file_origin = res.file[0];
                this.UploadForm.zip_type = "." + res.file[0].split(".")[1];
                _this.moxin_tow = true;
                this.uploadfunction(1);
            });
            // let config = {
            // 	onUploadProgress: (progressEvent) => {
            //         console.log(progressEvent);
            //         // progressEvent.loaded:已上传文件大小
            //         // progressEvent.total:被上传文件的总大小
            //         _this.zipUploadPercent = Number(
            //             (
            //                 (progressEvent.loaded / progressEvent.total) *
            //                 100
            //             ).toFixed(2)
            //         );
            //     },
            // }
            // requestShowuploadfile(formData,config).then((res) => {
            //     this.UploadForm.file_origin = res.file[0];
            //     this.UploadForm.zip_type = "." + res.file[0].split(".")[1];
            //     _this.moxin_tow = true;
            //     this.uploadfunction(1);
            // });
        },
        OriginBeforeAvatarUpload(file) {
            console.log(file);
            // console.log(file.name.split(".")[1])
            let format = file.name.split(".")[1];
            // if (format == "pak" || format == "b5a") {
            //     this.UploadForm.version_type = "2";
            // }
            let formData = new window.FormData(),
                _this = this,
                Storage = this.Account.GetStorage();
            formData.append("file[]", file.raw);
            this.UploadForm.name = file.name.split(".")[0];
            if (
                (Storage.total_storage - Storage.use_storage) * 1024 * 1024 <
                file.size
            ) {
                _this.$message({
                    message: "内部空间不足，请购买空间",
                    type: "warning",
                    offset: 100,
                });
                return;
            }
            _this.$message({
                message: "正在上传中，请稍后",
            });
            this.moxfile_origin = file;
            // var aa = {name:file.name,size:file.size}
            // this.MoxfileListOrigin.push(aa)
            console.log(this.MoxfileListOrigin);
        },
        BatchOriginBeforeAvatarUploadclick() {
            console.log("123123");
        },
        BatchOriginBeforeAvatarUpload(file, data) {
            let formData = new window.FormData(),
                _this = this,
                Storage = this.Account.GetStorage();
            formData.append("file[]", file.raw);
            if (
                (Storage.total_storage - Storage.use_storage) * 1024 * 1024 <
                file.size
            ) {
                _this.$message({
                    message: "内部空间不足，请购买空间",
                    type: "warning",
                    offset: 100,
                });
                return;
            }
            _this.$message({
                message: "正在上传中，请稍后",
            });
            console.log(this.BatchUpload, this.BatchOriginIndex);
            this.BatchUpload[this.BatchOriginIndex].moxfile_origin = file;
            this.BatchUpload[this.BatchOriginIndex].name =
                file.name.split(".")[0];
        },
        RelationUploadFormBeforeAvatarUpload(file) {
            console.log("脚本", file);
            this.jiaoben_filethumbnail = file;
        },
        RelationOriginBeforeAvatarUpload(file) {
            console.log("脚本", file);
            this.UploadForm.name = file.name.split(".")[0];
            // console.log(file.name.split('.')[0])
            this.jiaoben_file_origin = file;
        },
        upload_jiaoben(file, type) {
            let formData = new window.FormData(),
                _this = this,
                Storage = this.Account.GetStorage();
            formData.append("file[]", file.raw);
            formData.append("res_resource_type", 0);
            if (
                (Storage.total_storage - Storage.use_storage) * 1024 * 1024 <
                file.size
            ) {
                _this.$message({
                    message: "内部空间不足，请购买空间",
                    type: "warning",
                    offset: 100,
                });
                return;
            }
            requestjiaobenShowuploadfile(formData).then((res) => {
                this.UploadForm.file_document = res.file[0];
                this.UploadForm.file_document_ziptype =
                    "." + res.file[0].split(".")[1];
                this.jiaoben_one = true;
                this.uploadfunction(3);
            });
        },
        upload_jiaoben_ico(file, type) {
            let formData = new window.FormData(),
                _this = this,
                Storage = this.Account.GetStorage();
            console.log(file);
            formData.append("file[]", file.raw);
            formData.append("res_resource_type", 0);
            if (
                (Storage.total_storage - Storage.use_storage) * 1024 * 1024 <
                file.size
            ) {
                _this.$message({
                    message: "内部空间不足，请购买空间",
                    type: "warning",
                    offset: 100,
                });
                return;
            }
            requestShowuploadfile(formData).then((res) => {
                this.UploadForm.file_document_ico = res.file[0];
                this.jiaoben_tow = true;
                this.uploadfunction(3);
            });
        },
        donghuaUploadFormBeforeAvatarUpload(file) {
            this.donghua_filethumbnail = file;
        },
        donghuaOriginBeforeAvatarUpload(file, fileList) {
            this.UploadForm.name = file.name.split(".")[0];
            this.donghua_file_origin.push(file);
        },
        donghuaOriginBeforeAvatarUploadMP4(file, fileList) {
            this.donghua_file_origin.push(file);
            // this.UploadForm.zip_type = '.' + file.name.split('.')[1];
            // console.log(file,this.UploadForm.zip_type)
        },
        donghuaOriginBeforeAvatarUploadBFD(file, fileList) {
            this.donghua_file_origin.push(file);
        },
        upload_donghua(file, type) {
            let formData = new window.FormData(),
                _this = this,
                Storage = this.Account.GetStorage();
            for (let i = 0; i < file.length; i++) {
                console.log(file[i]);
                if (file[i] == null) {
                    continue;
                }
                formData.append("file[]", file[i].raw);
            }
            if (
                (Storage.total_storage - Storage.use_storage) * 1024 * 1024 <
                file.size
            ) {
                _this.$message({
                    message: "内部空间不足，请购买空间",
                    type: "warning",
                    offset: 100,
                });
                return;
            }
            this.zipFlag = true;
            axios({
                method: "POST",
                url: "https://res.bimshows.cn/platform/show/showuploadfile",
                data: formData,
                onUploadProgress: (progressEvent) => {
                    // console.log(progressEvent);
                    // progressEvent.loaded:已上传文件大小
                    // progressEvent.total:被上传文件的总大小
                    _this.zipUploadPercent = Number(
                        (
                            (progressEvent.loaded / progressEvent.total) *
                            100
                        ).toFixed(2)
                    );
                },
            }).then((res) => {
                this.zipFlag = false;
                this.zipUploadPercent = 0;
                for (let i = 0; i < res.file.length; i++) {
                    console.log(_this.shangchaun_type(res.file[i]));
                    if (_this.shangchaun_type(res.file[i]) == ".mp4") {
                        _this.UploadForm.file_mp4 = res.file[i];
                    } else if (
                        _this.shangchaun_type(res.file[i]) == ".png,.jpg"
                    ) {
                        _this.UploadForm.thumbnail = res.file[i];
                    } else if (_this.shangchaun_type(res.file[i]) == ".bfd") {
                        _this.UploadForm.file_backup = res.file[i];
                    } else if (
                        _this.shangchaun_type(res.file[i]) == ".bfm,.bfm2,.bfm3"
                    ) {
                        _this.UploadForm.file_origin = res.file[i];
                        console.log(
                            _this.shangchaun_type(res.file[i]),
                            _this.UploadForm.file_origin
                        );
                        this.UploadForm.zip_type =
                            "." + res.file[i].split(".")[1];
                    } else if (_this.shangchaun_type(res.file[i]) == ".zip") {
                        _this.UploadForm.file_origin = res.file[i];
                        this.UploadForm.zip_type =
                            "." + res.file[i].split(".")[1];
                    } else if (_this.shangchaun_type(res.file[i]) == ".bfm4") {
                        _this.UploadForm.file_origin = res.file[i];
                        this.UploadForm.zip_type =
                            "." + res.file[i].split(".")[1];
                    } else if (_this.shangchaun_type(res.file[i]) == ".bfm5") {
                        _this.UploadForm.file_origin = res.file[i];
                        this.UploadForm.zip_type =
                            "." + res.file[i].split(".")[1];
                    }
                }

                this.donghua_tow = true;
                this.uploadfunction(2);
            });

            // requestShowuploadfile(formData).then((res) => {
            //     // console.log(res.file)

            //     for (let i = 0; i < res.file.length; i++) {
            //         // console.log(_this.shangchaun_type(res.file[i]))
            //         if (_this.shangchaun_type(res.file[i]) == ".mp4") {
            //             _this.UploadForm.file_mp4 = res.file[i];
            //         } else if (
            //             _this.shangchaun_type(res.file[i]) == ".png,.jpg"
            //         ) {
            //             _this.UploadForm.thumbnail = res.file[i];
            //         } else if (_this.shangchaun_type(res.file[i]) == ".bfd") {
            //             _this.UploadForm.file_backup = res.file[i];
            //         } else if (
            //             _this.shangchaun_type(res.file[i]) == ".bfm,.bfm2,.bfm3"
            //         ) {
            //             _this.UploadForm.file_origin = res.file[i];
            //             console.log(
            //                 _this.shangchaun_type(res.file[i]),
            //                 _this.UploadForm.file_origin
            //             );
            //             this.UploadForm.zip_type =
            //                 "." + res.file[i].split(".")[1];
            //         } else if (_this.shangchaun_type(res.file[i]) == ".zip") {
            //             _this.UploadForm.file_origin = res.file[i];
            //             this.UploadForm.zip_type =
            //                 "." + res.file[i].split(".")[1];
            //         }
            //     }

            //     this.donghua_tow = true;
            //     this.uploadfunction(2);
            // });
        },
        //判断上传类型
        shangchaun_type(name) {
            if (
                name.split(".")[name.split(".").length - 1] == "jpg" ||
                name.split(".")[name.split(".").length - 1] == "png"
            ) {
                return ".png,.jpg";
            } else if (name.split(".")[name.split(".").length - 1] == "mp4") {
                return ".mp4";
            } else if (name.split(".")[name.split(".").length - 1] == "zip") {
                return ".zip";
            } else if (name.split(".")[name.split(".").length - 1] == "bfm4") {
                return ".bfm4";
            } else if (name.split(".")[name.split(".").length - 1] == "bfm5") {
                return ".bfm5";
            } else if (
                name.split(".")[name.split(".").length - 1] == "bfm" ||
                name.split(".")[name.split(".").length - 1] == "bfm2" ||
                name.split(".")[name.split(".").length - 1] == "bfm3"
            ) {
                return ".bfm,.bfm2,.bfm3";
            } else if (name.split(".")[name.split(".").length - 1] == "bfd") {
                return ".bfd";
            }
        },
    },
    mounted() {
        console.log("vip_type", this.Account.GetStorage());
        if (this.Account.GetSession().id == 24574) {
            this.bfdshow = true;
        }

        console.log();
        if (this.Account.GetStorage().vip_type == 100) {
            this.zidingyidonghua = false;
        }
        // zidingyidonghua
    },
};
</script>
<style scoped>
@import "../style/common.css";

.upload-demo {
    text-align: center;
}

.col-sm-10 p {
    margin-top: -30px;
    margin-left: 36px;
}

.straightforward-center {
    background: #d1e1f9;
}

a {
    text-decoration: none;
}

.main {
    background: #d1e0f9;
}

.mian_content {
    padding: 20px 20px;
    margin: 0 auto;
    background: white;
}

.fr {
    display: block;
    float: left;
    padding: 0 10px 10px 0;
}

.spacing {
    margin-bottom: 20px;
}

.spacing li a {
    color: #999;
}

.subscript {
    border-top: 1px solid #007bff;
    margin-top: 7px;
}

>>> .el-radio__input.is-checked .el-radio__inner {
    background: #b6492c;
    border-color: #b6492c;
}

>>> .el-radio__input.is-checked + .el-radio__label {
    color: #b6492c;
}

>>> .el-upload-dragger,
>>> .el-upload {
    width: 98%;
    padding-left: 2%;
    height: 150px;
    overflow: hidden;
}

>>> .el-button {
    width: 120px;
    border: 1px solid #c43e1b;
    margin: 60px auto 0;
    color: #c43e1b;
    display: block;
}

>>> .el-switch.is-checked .el-switch__core {
    background-color: #c43e1b;
    border-color: #c43e1b;
}

>>> .el-switch__label.is-active {
    color: #c43e1b;
}

>>> .el-input__inner:focus {
    border-color: rgb(203, 80, 49);
}

>>> .el-upload:focus {
    border-color: rgb(203, 80, 49);
}

>>> .el-upload:hover {
    border-color: rgb(203, 80, 49);
}

.el-upload-dragger:hover {
    border-color: rgb(203, 80, 49);
}

.el-upload-dragger:focus {
    border-color: rgb(203, 80, 49);
}

>>> .el-upload:focus .el-upload-dragger {
    border-color: rgb(203, 80, 49);
}

>>> .el-upload-dragger:hover {
    border-color: rgb(203, 80, 49);
}

>>> .el-upload-dragger .el-upload__text em {
    color: rgb(203, 80, 49);
}

>>> .el-radio.is-bordered.is-checked {
    border-color: rgb(203, 80, 49);
}

.text_int {
    /* text-indent: 20px; */
}
>>> .el-checkbox__input.is-checked .el-checkbox__inner,.el-checkbox__inner.is-indeterminate .el-checkbox__inner{
    background-color: #c43e1b !important;
    border-color: #c43e1b !important;
    
}
>>>.el-checkbox__inner:hover{
    border-color: rgb(203, 80, 49);
}
>>> .el-checkbox__input.is-focus .el-checkbox__inner{
    border-color: rgb(203, 80, 49) !important;
}
>>> .el-checkbox__input.is-checked+.el-checkbox__label{
    color: #c43e1b !important;
}

>>> .el-upload--picture-card {
    line-height: 200px;
}
</style>
